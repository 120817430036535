import { Layout, SEO } from '@components/common'
import { ProductCard } from '@components/sites/product'
import { Container, Grid, Marquee } from '@components/ui'
// import HomeAllProductsGrid from '@components/common/HomeAllProductsGrid'
import { LoadingDotsFullPage } from '@components/ui/LoadingDots'
import commerce from '@lib/sites/api/commerce'
import fetchStoreProfile from '@lib/sites/fetchStoreProfile'
import type {
  GetStaticPathsContext,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from 'next'
import DefaultErrorPage from 'next/error'
import fetchStoresForPaths from '@lib/sites/fetchStoresForPaths'
import ProfileHeader from '@components/sites/profile/ProfileHeader'
import { PublicStore } from 'generatedTypes/graphql'
import Head from 'next/head'
import { useRouter } from 'next/router'

export default function Home({
  products,
  filteredFeaturedProducts,
  profile,
}: {
  products: InferGetStaticPropsType<typeof getStaticProps>
  filteredFeaturedProducts: any
  profile: PublicStore
}) {
  const router = useRouter()

  if (router.isFallback) {
    return <LoadingDotsFullPage />
  }

  // This includes setting the noindex header because static files always return a status 200 but the rendered not found page page should obviously not be indexed
  if (!profile) {
    return (
      <>
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        <DefaultErrorPage statusCode={404} />
      </>
    )
  }

  const profilePhoto = profile.image
    ? profile.image
    : { src: '/android-chrome-512x512.png', width: 512, height: 512 }

  return (
    <>
      <SEO
        title={profile.name + ' store on Shopcat Storified Shopping'}
        description={
          profile.description ? profile.description : 'Shopcat Storified Shopping'
        }
        theme={profile.theme as string}
        openGraph={{
          title: profile.name + ' store on Shopcat Storified Shopping',
          images: [
            {
              url: profilePhoto.src,
              height: profilePhoto.height?.toString(),
              width: profilePhoto.width?.toString(),
            },
          ],
          description: profile.description ? profile.description : '',
        }}
      />
      <div className="sm:flex sm:min-h-[calc(100vh-6.5rem-1px)]">
        <ProfileHeader profile={profile} />
        <Container className="py-5 sm:w-[75%]">
          {filteredFeaturedProducts.length > 0 && (
            <div>
              <div className="pb-4 text-lg font-bold text-primary ">
                Featured
              </div>

              <Marquee>
                {filteredFeaturedProducts.map((product: any) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                    className="mr-3 rounded-xl max-w-[274px] flex flex-col justify-around"
                  />
                ))}
              </Marquee>
            </div>
          )}
          {products.length !== 0 && (
            <div>
              <div className="pb-4 text-lg font-bold text-primary">
                My recommended products:
              </div>
              <Grid>
                {products!.map((product: any, i: number) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                    imgProps={{
                      width: i === 0 ? 1080 : 540,
                      height: i === 0 ? 1080 : 540,
                      priority: true,
                    }}
                  />
                ))}
              </Grid>
            </div>
          )}

          {products.length === 0 && (
            <>
              <div className="pb-4 text-center text-lg font-bold text-primary">
                Nothing here yet!
              </div>
              <div className="pb-4 text-primary">
                Select some products to resell inside your creator app. Try
                refreshing in a few minutes.{' '}
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  )
}

export async function getStaticPaths() {
  const stores = await fetchStoresForPaths()
  const paths =
    stores.map((store: Pick<PublicStore, 'handle'>) => ({
      params: { site: store.handle },
    })) || []

  return {
    paths,
    fallback: true,
  }
}

export const getStaticProps = async ({
  preview,
  locale,
  locales,
  params,
}: GetStaticPropsContext) => {
  console.log('check locale inside static props', locale)
  if (locale === 'default') {
    console.log('ignoring default locale', JSON.stringify(params))
    return { notFound: true } as const
  }
  const { site } = params!

  const profile = await fetchStoreProfile(site as string)
  if (profile) {
    const commerceUrl = `https://${profile!.apiUrl}/api/2022-07/graphql.json`
    commerce.setConfig({ apiToken: profile!.apiToken, commerceUrl })
    const config = { locale, locales }

    const { products: featuredProducts } = await commerce.getProductsByTags({
      variables: {
        tags: ['featured'],
      },
      config,
      preview,
    })

    const filteredFeaturedProducts = featuredProducts.filter(product => product.tags?.includes('featured'));

    //initiate requests to fetch store data asynchronously
    // const pagesPromise = commerce.getAllPages({ config, preview })
    // const siteInfoPromise = commerce.getSiteInfo({ config, preview })

    const theme = profile?.theme ? profile.theme : 'default'

    const variables = profile?.tag
      ? { first: 100, query: `tag:${profile?.tag}` }
      : { first: 100 }

    const productsPromise = commerce.getAllProducts({
      variables,
      config,
      preview,
      // Saleor provider only
      ...({ featured: true } as any),
    })

    // const { pages } = await pagesPromise
    // const { categories, brands } = await siteInfoPromise
    //product request await at the very end assuming this was the last request, so it will need more time to resolve
    const { products } = await productsPromise

    return {
      props: {
        products,
        // categories,
        // brands,
        // pages,
        filteredFeaturedProducts,
        profile,
        theme,
        site,
        apiUrl: profile!.apiUrl,
        apiToken: profile!.apiToken,
        productDetailsAsStory: profile!.productDetailsAsStory,
      },
      revalidate: 60,
    }
  }

  return {
    props: {
      products: [],
      profile,
      theme: 'default',
      site,
      apiUrl: null,
      apiToken: null,
      productDetailsAsStory: true,
    },
    revalidate: 60,
  }
}

Home.Layout = Layout
