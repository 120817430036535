import cn from 'clsx'
import React, { forwardRef, AnchorHTMLAttributes } from 'react'

import {
  FaFacebook,
  FaFacebookMessenger,
  FaTwitter,
  FaYoutube,
  FaTiktok,
  FaWhatsapp,
  FaInstagram,
  FaLinkedin,
  FaSnapchat,
  FaPinterest,
  FaSpotify,
  FaLine,
  FaVimeo,
  FaWeixin,
  FaViber,
  FaTelegram,
  FaWeibo,
  FaDribbble,
  FaTwitch,
  FaBehance,
  FaSoundcloud,
  FaLink,
  FaPatreon,
  FaAmazon,
  FaEtsy,
  FaDiscord,
} from 'react-icons/fa'
import { SiSubstack, SiClubhouse } from 'react-icons/si'
import { IconType } from 'react-icons/lib'

const socialIconsCollection = {
  FACEBOOK: FaFacebook,
  FACEBOOKMESSENGER: FaFacebookMessenger,
  TWITTER: FaTwitter,
  YOUTUBE: FaYoutube,
  TIKTOK: FaTiktok,
  WHATSAPP: FaWhatsapp,
  INSTAGRAM: FaInstagram,
  LINKEDIN: FaLinkedin,
  SNAPCHAT: FaSnapchat,
  PINTEREST: FaPinterest,
  SPOTIFY: FaSpotify,
  LINE: FaLine,
  VIMEO: FaVimeo,
  WEIXIN: FaWeixin,
  VIBER: FaViber,
  TELEGRAM: FaTelegram,
  WEIBO: FaWeibo,
  DRIBBBLE: FaDribbble,
  TWITCH: FaTwitch,
  BEHANCE: FaBehance,
  SOUNDCLOUD: FaSoundcloud,
  LINK: FaLink,
  SUBSTACK: SiSubstack,
  CLUBHOUSE: SiClubhouse,
  PATREON: FaPatreon,
  AMAZON: FaAmazon,
  ETSY: FaEtsy,
  DISCORD: FaDiscord,
}
const socialUrl = {
  FACEBOOK: (h: string) =>
    h.startsWith('@')
      ? `https://www.facebook.com/${h.slice(1)}`
      : h.startsWith('https://') ||
        h.startsWith('https://') ||
        h.startsWith('www.')
      ? h
      : `https://www.facebook.com/${h}`,
  FACEBOOKMESSENGER: (h: string) =>
    h.startsWith('@')
      ? `https://m.me/${h.slice(1)}`
      : h.startsWith('https://') ||
        h.startsWith('http://') ||
        h.startsWith('www.')
      ? h
      : `https://m.me/${h}`,
  TWITTER: (h: string) =>
    h.startsWith('@')
      ? `https://twitter.com/${h.slice(1)}`
      : h.startsWith('https://') ||
        h.startsWith('http://') ||
        h.startsWith('www.')
      ? h
      : `https://twitter.com/${h}`,
  YOUTUBE: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://') || h.startsWith('www.')
      ? h
      : `https://www.youtube.com/${h}`,
  TIKTOK: (h: string) =>
    h.startsWith('@')
      ? `https://www.tiktok.com/${h}`
      : h.startsWith('https://') ||
        h.startsWith('http://') ||
        h.startsWith('www.')
      ? h
      : `https://www.tiktok.com/@${h}`,
  WHATSAPP: (h: string) =>
    h.startsWith('+') ? `https://wa.me/${h.slice(1)}` : `https://wa.me/${h}`,
  INSTAGRAM: (h: string) =>
    h.startsWith('@')
      ? `https://www.instagram.com/${h.slice(1)}`
      : h.startsWith('https://') ||
        h.startsWith('http://') ||
        h.startsWith('www.')
      ? h
      : `https://www.instagram.com/${h}`,
  LINKEDIN: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://www.linkedin.com/in/${h}`,
  SNAPCHAT: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://www.snapchat.com/add/${h}`,
  PINTEREST: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://www.pinterest.com/${h}`,
  SPOTIFY: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://open.spotify.com/artist/${h}`,
  LINE: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://line.me/ti/p/${h}`,
  VIMEO: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://vimeo.com/${h}`,
  WEIXIN: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://weixin.qq.com/${h}`,
  VIBER: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://viber.com/${h}`,
  TELEGRAM: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://t.me/${h}`,
  WEIBO: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://weibo.com/${h}`,
  DRIBBBLE: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://dribbble.com/${h}`,
  TWITCH: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://twitch.tv/${h}`,
  BEHANCE: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://www.behance.net/${h}`,
  SOUNDCLOUD: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://soundcloud.com/${h}`,
  LINK: (h: string) => h,
  SUBSTACK: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://${h}.substack.com/`,
  CLUBHOUSE: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://clubhouse.com/${h}`,
  PATREON: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://www.patreon.com/${h}`,
  AMAZON: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://www.amazon.com/shop/${h}`,
  ETSY: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://www.etsy.com/shop/${h}`,
  DISCORD: (h: string) =>
    h.startsWith('https://') || h.startsWith('http://')
      ? h
      : `https://discord.com/invite/${h}`,
}

// creating literal string types for social link icons
const tuple = <T extends string[]>(...args: T) => args
const socialIconsArray = tuple(
  ...Object.keys(socialIconsCollection)
) as (keyof typeof socialIconsCollection)[]

export interface SocialLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  handleOrUrl: string
  className?: string
  type: typeof socialIconsArray[number]
  width?: string | number
}

// eslint-disable-next-line react/display-name
const SocialLink: React.FC<SocialLinkProps> = forwardRef((props) => {
  const { className, width, type = 'LINK', handleOrUrl, style = {} } = props
  const rootClassName = cn(
    'h-12 w-12 rounded-xl border border-base-300 fill-accent p-3',
    className
  )

  const resolvedHref = (
    handleOrUrl: string,
    type: SocialLinkProps['type']
  ): string => socialUrl[type](handleOrUrl)

  const Component: IconType = socialIconsCollection[type]
    ? socialIconsCollection[type]
    : FaLink

  return (
    <a
      href={resolvedHref(handleOrUrl, type)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Component
        className={rootClassName}
        style={{
          width,
          ...style,
        }}
      ></Component>
    </a>
  )
})

export default SocialLink
