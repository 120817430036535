
interface ProductTagProps {
  className?: string
  name: string
  fontSize?: number
}

const ProductTag: React.FC<ProductTagProps> = ({ name }) => {
  return (
    <div className="w-full">
      <h3 className="p-2 text-sm font-medium">{name}</h3>
    </div>
  )
}

export default ProductTag
