import { PublicStore } from 'generatedTypes/graphql'
import Image from 'next/image'
import SocialLink from '../SocialLink'
import cn from 'clsx'

const ProfileHeader = ({ profile }: { profile: PublicStore }) => {
  const profilePhoto = profile.image
    ? profile.image
    : { src: '/android-chrome-512x512.png', width: 512, height: 512 }

  const socialLinksAdded = profile.socialLinks.length > 0
  const customLinksAdded = profile.customLinks.length > 0

  return (
    <div className="flex flex-col items-center bg-gradient-background p-5 w-full sm:w-[25%] ">
      <div className="flex max-w-xl flex-col items-center">
        <div className="relative h-24 w-24 overflow-hidden rounded-full border-4 border-white">
          <a href="/">
            <Image
              className={`bg-base-0`}
              src={profilePhoto.src}
              width={profilePhoto.width!}
              height={profilePhoto.height!}
              sizes="(max-width: 640px) 50vw, 640px"
              alt="Profile Image"
              layout="fill"
              objectFit="cover"
              data-cy="profile-image"
            />
          </a>
        </div>
        <div className="my-2 font-sans text-2xl font-bold text-primary">
          {profile.name}
        </div>
        <div className="text-center text-sm text-baseColor ">
          {profile.description}
        </div>
      </div>
      
      {(customLinksAdded || socialLinksAdded) && (
        <div className="mt-5 flex flex-col items-center w-full gap-4 rounded-3xl bg-white p-6">
          {socialLinksAdded && (
            <div className="grid grid-cols-4 gap-4">
              {profile.socialLinks.map((link) => (
                <SocialLink
                  key={link.type}
                  type={link.type as any}
                  handleOrUrl={link.handleOrUrl}
                />
              ))}
            </div>
          )}
          {customLinksAdded && (
            <div className="flex w-full flex-row flex-wrap gap-4">
              {profile.customLinks.map((link) => {
                return (
                  <a
                    key={link.url}
                    href={
                      /^((http|https|ftp):\/\/)/.test(link.url)
                        ? link.url
                        : 'http://' + link.url
                    }
                    type="button"
                    className={cn(
                      `ali inline-flex min-h-[3rem] cursor-pointer items-center justify-center rounded-xl bg-gradient-button 
                 text-center text-sm font-semibold uppercase leading-6 tracking-wide text-accent-50 shadow 
                transition duration-150 ease-in-out 
                hover:h-auto hover:scale-[1.02] hover:shadow-xl 
                active:scale-[0.98] active:shadow-inner`,
                      link.type === 'WIDE' ? 'w-full' : 'w-[calc(50%-0.5rem)]',
                      'md:w-full'
                    )}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    <span className=" my-2 px-2 line-clamp-2 hover:line-clamp-6">
                      {link.name ? link.name : link.url}
                    </span>
                  </a>
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ProfileHeader
