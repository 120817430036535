import cn from 'clsx'

interface PriceTagProps {
  className?: string
  price: string
  fontSize?: number
  basePrice?: string | null
  isListPriceDisplayed?: boolean
}

const PriceTag: React.FC<PriceTagProps> = ({ price, basePrice, className = '', isListPriceDisplayed }) => {
  return (
    <div
      className={cn(
        'absolute bottom-0 right-0 z-20 rounded-tl-lg bg-base-0 py-1 pl-2 flex flex-col items-end',
        className
      )}
    >
      {basePrice && isListPriceDisplayed && <div className="text-sm font-semibold text-secondary line-through">{basePrice}</div>}
      <div className="text-sm font-semibold text-secondary no-underline">{price}</div>
    </div>
  )
}

export default PriceTag
