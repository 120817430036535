import type { Product } from '@commerce/types/product'
import usePrice from '@framework/product/use-price'
import cn from 'clsx'
import Image, { ImageProps } from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
import { getDefaultProductVariant } from '../helpers'
import PriceTag from '../PriceTag'
import ProductTag from '../ProductTag'
import s from './ProductCard.module.css'

interface Props {
  className?: string
  product: Product
  noNameTag?: boolean
  imgProps?: Omit<ImageProps, 'src' | 'layout' | 'placeholder' | 'blurDataURL'>
  variant?: 'default' | 'slim' | 'simple'
}

const placeholderImg = '/product-img-placeholder.svg'

const ProductCard: FC<Props> = ({
  product,
  imgProps,
  className,
  noNameTag = false,
  variant = 'default',
}) => {
  const productVariant = getDefaultProductVariant(product)
  const isListPriceDisplayed =
    productVariant?.listPrice! > productVariant?.price!

  const { price, basePrice } = usePrice(
    productVariant
      ? {
          amount: productVariant.price,
          baseAmount: productVariant?.listPrice!,
          currencyCode: product.price.currencyCode!,
        }
      : undefined
  )

  const rootClassName = cn(
    s.root,
    { [s.slim]: variant === 'slim', [s.simple]: variant === 'simple' },
    ' rounded-xl',
    className
  )

  return (
    <Link href={`/product/${product.slug}`}>
      <a className={rootClassName} aria-label={product.name}>
        {variant === 'slim' && (
          <>
            {/* <div className={s.header}>
              <span>{product.name}</span>
            </div> */}
            {product?.images && (
              <div>
                <Image
                  quality="85"
                  src={product.images[0]?.url || placeholderImg}
                  alt={product.name || 'Product Image'}
                  height={320}
                  width={320}
                  layout="fixed"
                  {...imgProps}
                />
              </div>
            )}
          </>
        )}

        {variant === 'simple' && (
          <>
            {/* {process.env.COMMERCE_WISHLIST_ENABLED && (
              <WishlistButton
                className={s.wishlistButton}
                productId={product.id}
                variant={product.variants[0]}
              />
            )} */}
            {/* {!noNameTag && (
              <div className={s.header}>
                <h3 className={s.name}>
                  <span>{product.name}</span>
                </h3>
                <div className={s.price}>
                  {`${price} ${product.price?.currencyCode}`}
                </div>
              </div>
            )} */}
            <div className={s.imageContainer}>
              {product?.images && (
                <div>
                  <Image
                    alt={product.name || 'Product Image'}
                    className={s.productImage}
                    src={product.images[0]?.url || placeholderImg}
                    sizes="(max-width: 640px) 45vw, 640px"
                    height={540}
                    width={540}
                    quality="85"
                    layout="responsive"
                    {...imgProps}
                  />
                </div>
              )}
            </div>
          </>
        )}

        {variant === 'default' && (
          <>
            {/* {process.env.COMMERCE_WISHLIST_ENABLED && (
              <WishlistButton
                className={s.wishlistButton}
                productId={product.id}
                variant={product.variants[0] as any}
              />
            )} */}
            <div className="relative">
              <div className={s.imageContainer}>
                {product?.images && (
                  <div>
                    <Image
                      alt={product.name || 'Product Image'}
                      className={s.productImage}
                      src={product.images[0]?.url || placeholderImg}
                      sizes="(max-width: 640px) 45vw, 640px"
                      height={540}
                      width={540}
                      quality="85"
                      layout="responsive"
                      {...imgProps}
                    />
                  </div>
                )}{' '}
              </div>
              <PriceTag
                price={price}
                basePrice={basePrice}
                isListPriceDisplayed={isListPriceDisplayed}
              />
            </div>

            <ProductTag name={product.name} />
          </>
        )}
      </a>
    </Link>
  )
}

export default ProductCard
